import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import "slick-carousel/slick/slick.css"
import "../../styles/pages/contact-lenses.scss"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import CartContext from "../../context/cartContext"
import ProductSlider from "../../components/ProductSlider"
import VideoModule from "../../components/VideoModule"
import ProductSectionAdvantages from "../../components/ProductSectionAdvantages"
import HowHubbleWorksCarousel from "../../components/HowHubbleWorksCarousel"
import AboutProductSection from "../../components/AboutProductSection"
import Breadcrumbs from "../../components/Breadcrumbs"
import ProductValueProps from "../../components/ProductValueProps"
import {
  colorTags,
  skyhyFaq,
  hubbleValueProps,
  hubbleDifference,
  contactsRelatedProducts,
  skyhyAdvantages, getHubblePricing
} from "../../services/data"
import ProductWithHubble from "../../components/ProductWithHubble"
import FAQBlock from "../../components/FAQBlock"
import ProductDetails from "../../components/ProductDetails"
import OrderFlow from "../../components/OrderFlow"
import NewButton from "../../components/NewButton"
import RelatedFrames from "../../components/RelatedFrames"
import { StaticImage } from "gatsby-plugin-image"
import useNewRxFlow from "../../utils/useNewRxFlow"
import ContactsDetailsMobile from "../../components/ContactsDetailsMobile"
import NewRxFlow from "../../components/NewRxFlow"
import ReviewCarousel from "../../components/ReviewCarousel"
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";
import avgProducts from "../../services/avg-products.json";
import {hubbleList, otpHubbleList} from "../../components/ContactsPrescription/mockedData";
import {productInfo} from "../../components/Intake/Prescription/mockedData";
import usePromo from "../../utils/usePromo";
import Banner from "../../components/Banner";

const SkyhyLenses = () => {
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()
  const [slides] = useState([
    "Pages/ContactsPDP/Product-Images/SkyHy-by-Hubble-PDP-01.png",
    "Pages/ContactsPDP/Product-Images/SkyHy-by-Hubble-PDP-02.png",
    "Pages/ContactsPDP/Product-Images/SkyHy-by-Hubble-PDP-03.png",
    "Pages/ContactsPDP/Product-Images/SkyHy-by-Hubble-PDP-04.png",
  ])
  const bulletPointsNoFda = [
    "Doctors’ top choice: 7 out of 10 new prescriptions are for silicone hydrogel contacts",
    "Daily silicone hydrogel contact lenses designed for maximum breathability",
    "Single vision soft contact lenses made from Olifilcon B (a silicone hydrogel material)",
    "Special surface technology keeps moisture locked in for more comfortable, hydrated, all-day wear",
    "Flexible construction molds to the curve of your eye for a snug fit",
    "A smart multi-curvature design to prevent unwanted movement—even when you blink",
  ]
  const isPromo = usePromo()
  const hubblePricing = getHubblePricing()
  const [otpPurchase, setOtpPurchase] = useState(false)

  const bulletPointsFda = "FDA approved"

  const bulletPoints = [...bulletPointsNoFda, isNewRx || isNewRxDesktop ? null : bulletPointsFda].filter(Boolean)

  const [show, setShow] = useState(false)
  const { cartItems, createOrder, setHeaderColor, clearOrder, setIsSkyhy, currentItem } = useContext(
    CartContext
  )

  const hubble_lenses = cartItems.filter(obj => {
    return obj.type === "contacts"
  })

  const item = {
    title: "SkyHy by Hubble Contact Lenses",
    subheader: "Our most breathable all-day wear daily contact lenses made with silicone hydrogel.",
    price: Number(hubblePricing.skyhy.subPrice),
    offPrice: Number(hubblePricing.skyhy.firstPrice),
    percentOff: Number(hubblePricing.skyhy.percentOff),
    baseCurve: 8.7,
    diameter: 14.1,
    material: "Olifilcon B (a silicone hydrogel material)",
    manufacturer: "Visco",
    id: 2,
    otpPrice: 98.99,
    otpQuantity: 90,
    subscriptionSubtitle: "66% OFF Your First Order",
    tag: "most popular",
  }

  const type = "contacts"

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.removeItem("previousPage")
      const urlParams = new URLSearchParams(window?.location.search)
      const rxflow = urlParams.get('rxflow')
      const newRxFlow = urlParams.get('newRxFlow')
      if (rxflow === "true" || newRxFlow === "true") {
        setShow(true)
      }


      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          items: [
            {
              item_id: 7546170802376,
              item_name: 'SkyHy by Hubble Contact Lenses',
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A',
              item_category4: 'Olifilcon B',
              item_category5: 'N/A',
              item_list_id: `Contacts`,
              item_variant: 42853286281416,
              price: 17.99,
              quantity: 1,
              index: 0
            }
          ]
        }
      })
    }



    setIsSkyhy(true)
  }, [])



  useEffect(() => {
    const newItem = {
      id: '',
      type
    }

    const newOrder = {
      email: "",
      state: "",
      doctor: "",
      prescription: {
        rightEye: {
          power: "",
          baseCurve: "8.7",
          diameter: "14.1",
        },
        leftEye: {
          power: "",
          baseCurve: "8.7",
          diameter: "14.1",
        },
      },
      isOneEye: false,
    }

    setHeaderColor("purple")
    if (!isNewRx && !currentItem) createOrder(newItem, newOrder)
  }, [cartItems, isNewRx])

  useEffect(() => {
    return () => {
      setHeaderColor("black")
      // if (!isNewRx && !currentItem) clearOrder()
    }
  }, [])

  const schemaMarkup = [
    {
      "@context" : "https://schema.org/",
      "@type" : "Product",
      "name" : "SkyHy by Hubble Contact Lenses",
      "image" : "https://www.hubblecontacts.com/static/c63b8c7fedc861d5a54eef96097e3022/a18a4/SkyHy-by-Hubble-PDP-02.webp",
      "description" : "Our most breathable all-day wear daily contact lenses made with silicone hydrogel. Healthy eyes love oxygen. Oxygen makes contact lenses more comfortable. SkyHy by Hubble lenses have increased oxygen permeability for enhanced comfort and more breathability. New customers get 40% off!",
      "Brand":
      {
      "@type" : "Thing",
      "name" : "Hubble"
      },
      "Logo" : "https://cdn.shopify.com/s/files/1/1516/5228/t/3/assets/full_logo_red.svg?v=14192704095517354305",
      "Offers":
      [
      {
      "@type" : "Offer",
      "url " :"https://www.hubblecontacts.com/contact-lenses/skyhy-by-hubble-lenses/",
      "availability" : "https://schema.org/InStock",
      "Price":"$17.99/eye",
      "priceCurrency":"USD",
      "priceValidUntil":"2024-12-31"
      }
      ]
    },
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Hubble Contacts How We Make Ordering Breathable Contact Lenses Online Easy",
      "description": "At Hubble, we believe ordering our breathable silicone hydrogel contact lenses online should be easy. That’s why we’ve made Hubble’s personalized contact lens subscription service the easiest prescription daily contacts experience in the world. You can easily order breathable contacts in your contact lens prescription online. Order SkyHy by Hubble contacts and start your subscription of our daily silicone hydrogel prescription contact lenses.",
      "thumbnailUrl": "https://i.ytimg.com/vi/w4YCLxsUxyI/hqdefault.jpg",
      "uploadDate": "2023-03-20",
      "duration": "PT1M15S",
      "contentUrl": "https://www.hubblecontacts.com",
      "embedUrl": "https://www.youtube.com/embed/w4YCLxsUxyI"
    },
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "What are the differences between SkyHy, Hydro, and Classic Hubble contact lenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Great question! Let’s start with the similarities: All of Hubble’s daily contact lenses are FDA-approved and are offered at low prices as part of our customizable subscription model. You can compare our contacts to find the right option for you. If you’re looking for a little more detail, our hydrating Hydro by Hubble contact lenses are made from Hioxifilcon A and feature a higher water content. The materials mimic and bind with natural tears, designed to lock in moisture and quickly rehydrate your eyes. Plus, our hygienic touch technology packaging ensures minimal contact with your daily contact lenses and is the world’s slimmest.<p>If you're in search of cost-effective contacts with UV protection, Classic Contacts by Hubble are the perfect choice. Crafted from methafilcon A hydrogel, these affordable daily contact lenses ensure all-day comfort and crystal-clear vision, starting at just $1.</p><p> SkyHy is the newest member of the Hubble contact lens family. Our most breathable contact lenses yet are made with silicone hydrogel, and feature increased oxygen permeability for enhanced comfort and special flexible material that keeps moisture locked and your eyes naturally hydrated throughout the day.</p>"
          }
        },
        {
          "@type": "Question",
          "name": "Are Hubble prescription daily contact lenses FDA-approved?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, our Hubble prescription contact lenses have been FDA approved for sale in the US for over a decade."
          }
        },
        {
          "@type": "Question",
          "name": "Can teenagers wear Hubble’s breathable daily contact lenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, all patients ages 13+, who have a valid contact lens prescription, can wear Hubble daily breathable silicone hydrogel contact lenses. Please note: patients under the age of eighteen must receive parental permission before placing an order for our prescription contact lenses online with Hubble."
          }
        },
        {
          "@type": "Question",
          "name": "Does Hubble sell bi-weekly or monthly prescription contact lenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Hubble only sells daily contact lenses because wearing new prescription contact lenses every day is the safest and most comfortable way to wear contacts."
          }
        },
        {
          "@type": "Question",
          "name": "Can I use water or contact lens solution to clean or store my SkyHy by Hubble silicone hydrogel contact lenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "No! SkyHy by Hubble lenses are daily contact lenses, designed for individual use. Please do not attempt to wear our prescription contact lenses for more than one day."
          }
        }
      ]
    },

    {
      "@context" : "https://schema.org",
      "@url" : "https://www.hubblecontacts.com/contact-lenses/skyhy-by-hubble-lenses/",
      "@type" : "webpage",
      "name" : "Hubble Contacts SkyHy Lenses: Most breathable all-day wear daily contact lenses",
      "description" : "SkyHy by Hubble lenses are breathable all-day wear daily contact lenses made with silicone hydrogel. 40% off for new customers.",
      "publisher" :
      {
      "@type" : "Organization",
      "name" : "Hubble"
      }
      }

  ]

  const handleGaClick = () => {
    if (isNewRx || isNewRxDesktop) {
      navigate(`/contact-lenses/skyhy-by-hubble-lenses/?newRxFlow=true`)
    }
    setShow(true)
    if (typeof window !== "undefined") {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "select_item",
        ecommerce: {
          items: [
            {
              item_id: 7546170802376,
              item_name: 'SkyHy by Hubble Contact Lenses',
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A',
              item_category4: 'Olifilcon B (a silicone hydrogel material)',
              item_category5: 'N/A',
              item_list_id: `Contacts`,
              item_variant: 42853286281416,
              price: 17.99,
              quantity: 1,
              index: 0
            }
          ]
        }
      });
    }
  }

  const validateBrandPrescription = (item, prescription) => {
    const hubbleBrand =
      item.title.toLowerCase().includes("hubble classic") && "default" ||
      item.title.toLowerCase().includes("skyhy by") && "skyhy" ||
      item.title.toLowerCase().includes("hydro by") && "hydro"
    const prescriptionSettings = {...productInfo[hubbleBrand || item.cc_product_id]};
    prescriptionSettings.eye_powers = [
      { value: 'oneEye', label: `` },
      ...prescriptionSettings.eye_powers
    ]
    const eyes = [prescription.leftEye, prescription.rightEye]

    if (eyes[0].power === "oneEye" && eyes[1].power === "oneEye") return false;

    return eyes.every(eye => {
      if (eye.power === "oneEye") return true;

      return Object.entries(eye).every(([key, value]) => {
        let customKeys = {
          "power": "eye_powers",
          "addPower": "add_power",
          "baseCurve": "base_curve_values"
        }

        let _key = customKeys[key] || key;

        if (key === "diameter" || key === "baseCurve" && (
          !Array.isArray(prescriptionSettings[_key]) ||
          Array.isArray(prescriptionSettings[_key]) && prescriptionSettings[_key].length < 2)
        )
          return true

        if (typeof prescriptionSettings[_key] === "object")
          if (typeof prescriptionSettings[_key][0] === "object") {
            return prescriptionSettings[_key].some(({value: settingsValue}) => settingsValue == value)
          } else {
            return prescriptionSettings[_key].some((settingsValue) => settingsValue == value)
          }
        else
          return prescriptionSettings[_key] == value
      }) && Object.keys(prescriptionSettings).every(key => {
        let customKeys = {
          "eye_powers": "power",
          "add_power": "addPower",
          "base_curve_values": "baseCurve"
        }

        let _key = customKeys[key] || key;

        return !!eye[_key]
      })
    })
  }

  return (
    <Layout className="hubble-lenses">
      <SEO
        title="Hubble Contacts SkyHy Lenses: Breathable Silicone Hydrogel Contact Lenses"
        description="SkyHy by Hubble lenses are breathable daily contacts made with silicone hydrogel. Start your automatic subscription and never run out of prescription contacts. Exclusive discount for new customers."
        schemaMarkup={schemaMarkup} />
      <Banner
        bannerClass="purple discount-banner promo-banner pcp extra-padding"
        bannerText="Get your first order of SkyHy or Hydro by Hubble for a special reduced price."
      />
      {isNewRx ?
        <ContactsDetailsMobile
          item={item} type={type} slides={slides} handleGaClick={handleGaClick}
          lenses={hubble_lenses} isSkyhy={true} tagText='Premium Pick' tagColor='orange'
          otpPurchase={otpPurchase} setOtpPurchase={setOtpPurchase}
        /> :
        <div className="top-section">
          <NewButton
            label={"Buy Now"}
            color={"purple"}
            onClick={() => handleGaClick()}
            disabled={hubble_lenses?.length !== 0}
            className={'mobile-only'}
            stickyScroll={true} />
          <ProductSlider slides={slides} type={type} product={item} containImage centerImage tagText='Premium Pick' tagColor='orange' isHubble />
          <ProductDetails item={item} type={type} skyhy={true} onClick={() => handleGaClick()}
                          otpPurchase={otpPurchase} setOtpPurchase={setOtpPurchase} />
        </div>
      }
      <ProductValueProps props={hubbleValueProps()} type={type} />
      <div className="product-info">
        <div className="grid-section">
          <Breadcrumbs
            links={[
              { to: "/", label: "Homepage" },
              { to: "/contact-lenses/hubble", label: "Hubble Contact Lenses" },
              { label: "SkyHy Contact Lenses by Hubble" },
            ]}
          />
        </div>
        {!isNewRx &&
          <>
            <AboutProductSection
              title="Hubble Lenses"
              subtitle="Breathable Silicone Hydrogel Daily Contact Lenses"
              type={type}
              description={`Healthy eyes love oxygen. Oxygen makes contact lenses more comfortable. And SkyHy by Hubble lenses have increased oxygen permeability for enhanced comfort and more breathability.`}
              bullet_points={bulletPoints}
            />
            <ProductSectionAdvantages list={skyhyAdvantages} />
          </>}

        <HowHubbleWorksCarousel color={colorTags[type]} />
        <div className="grid-section lifestyle-shot">
          <div className="desktop-lifestyle">
            <StaticImage
              src="../../images/Pages/ContactsPDP/Hubble-Contacts-PDP-Inset-Image.jpg"
              alt="Cheerful woman putting in her SkyHy by Hubble breathable contact lens"
            />
          </div>
          <div className="mobile-lifestyle">
            <StaticImage
              src="../../images/Pages/ContactsPDP/Hubble-Contacts-PDP-Inset-Image-Mobile.jpg"
              alt="Cheerful woman putting in her SkyHy by Hubble breathable contact lens"
            />
          </div>
        </div>
        <ProductWithHubble type={type} items={hubbleDifference} />
        <VideoModule
          type={type}
          video={"https://www.youtube.com/embed/NKxNYzCRGwg"}
          image="Pages/ContactsPDP/Hubble-Contacts-PDP-Video-Still.jpg"
          alt_text="Woman unboxing a new delivery of Hubble Contacts"
        />
        {isNewRx &&
          <AboutProductSection
            title="Hubble Lenses"
            subtitle="Breathable Silicone Hydrogel Daily Contact Lenses"
            type={type}
            description={`Healthy eyes love oxygen. Oxygen makes contact lenses more comfortable. And SkyHy by Hubble lenses have increased oxygen permeability for enhanced comfort and more breathability.`}
            bullet_points={bulletPoints}
          />}
        {/* <div id="reviews"></div> */}
        {/* <ReviewCarousel color={colorTags[type]} page="contacts" /> */}
        {isNewRx && <ProductSectionAdvantages list={skyhyAdvantages} />}
        <FAQBlock faqList={skyhyFaq} color={colorTags[type]} />
      </div>
      {isNewRx || isNewRxDesktop ?
        <NewRxFlow type={type} show={show} setShow={setShow} brand='skyhy' onHide={() => setShow(false)}
                   validateBrandPrescription={validateBrandPrescription}
                   otpPurchase={otpPurchase}
                   optionsList={[{options: [...avgProducts.flatMap(e => e.options), {
                       id: -1,
                       title: "Browse 50+ more brands at contactscart.com",
                       price: "",
                       value: -1,
                       link: "https://www.contactscart.com/",
                     }, ...(otpPurchase || cartItems.find(e => e.otpPurchase) ? otpHubbleList : hubbleList(hubblePricing))]}]}/> :
        <OrderFlow type={type} show={show} setShow={setShow} brand='skyhy' />}
      <RelatedFrames
        relatedFrames={contactsRelatedProducts}
        type={type}
        linkTo="/contact-lenses"
      />
    </Layout>
  )
}
export default SkyhyLenses
