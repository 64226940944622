import React from "react"
import WebpImage from "../WebpImage";
import {useAbTest as getAbTest}  from '../../utils/useAbTest'
import "./style.scss"

const ProductSectionAdvantages = ({ list }) => {
  if(!list || !list.length) return '';
  const isPaidShipping = getAbTest()

  return (
    <div className="product-section-advantages">
      <div className="grid-section">
        <div className="advantages">
          {list.map(({ title, description, image, alt }) => (
            <div className="advantages-item" key={title}>
              <div className="image-wrapper">
              {image && <WebpImage className="image" fileName={image} alt={alt} />}
              </div>
              <div className="text-block">
                <h3 className="text h4 title">{title}</h3>
                <p className="text h7 description">{(title == "Start Your Subscription for $1" && isPaidShipping) ? "Take advantage of our first-time order offer and receive your starter park of contact lenses for only $1, plus shipping." : description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProductSectionAdvantages
